import { API } from 'client-website-ts-library/services';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  // Sell Dropdown
  {
    path: '/forms/sales-appraisal/',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  // Buy Dropdown
  {
    path: '/inspection-times',
    name: 'Inspection Times',
    component: () => import('../views/InspectionTimes.vue'),
  },
  {
    path: '/open-homes',
    name: 'Open Homes',
    component: () => import('../views/InspectionTimes.vue'),
  },
  {
    path: '/calculators',
    name: 'Calculators',
    meta: {
      hide: true,
    },
    component: () => import('../views/Calculators.vue'),
  },
  // Rent
  {
    path: '/rental-appraisal',
    name: 'Rental Appraisal',
    component: () => import('../views/RentalAppraisal.vue'),
  },
  {
    path: '/maintenance',
    name: 'Maintenance Request',
    component: () => import('../views/MaintenanceRequest.vue'),
  },
  // Landlord
  {
    path: '/renting-a-property',
    name: 'Renting a Property',
    component: () => import('../views/RentingAProperty.vue'),
  },
  {
    path: '/landlord-services',
    name: 'Landlord Services',
    component: () => import('../views/LandlordServices.vue'),
  },
  {
    path: '/landlord-obligations',
    name: 'Landlord Obligations',
    component: () => import('../views/LandlordObligations.vue'),
  },
  {
    path: '/forms/rental-appraisal',
    name: 'Rental Appraisal',
    component: () => import('../views/RentalAppraisal.vue'),
  },
  // Blog
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },
  // Switch to us
  {
    path: '/switch',
    name: 'Switch to Us',
    component: () => import('../views/SwitchToUs.vue'),
  },
  // About us Dropdown
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/people',
    name: 'Our People',
    component: () => import('../views/People.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  {
    path: '/make-explore-your-home',
    name: 'Make Explore Property Your Home',
    component: () => import('../views/MakeExploreYourHome.vue'),
  },
  // Centralised Listing Page
  {
    // Listing search page (with the map)
    path: '/listings/:type(for-sale|land-for-sale|for-rent|commercial|sold|leased)',
    name: 'Listings',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/Listings.vue'),
  },
  {
    // Dynamic Pages
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Listing',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/people/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blogs',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogDetails.vue'),
  },
  {
    path: '/people/:id/properties/sold',
    name: 'Profile Properties Sold',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/people/:id/properties',
    name: 'Profile Properties',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/appraisals/:name/:id',
    name: 'Appraisal Details',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/ViewAppraisal.vue'),
  },
  // Other
  {
    path: '/video-appraisal-request',
    name: 'Video Appraisal',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/ViewAppraisalRequest.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    meta: {
      hide: true,
    },
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/NotFound.vue'),
    beforeEnter: (to, from, next) => {
      if (to.fullPath) {
        API.Slugs.Resolve(to.fullPath.split(' ').join('-')).then((slug) => {
          switch (slug.Type) {
            case 'Listing':
              next({
                path: `/listings/${slug.Slug}`,
                name: slug.Slug,
                replace: true,
              });
              break;
            case 'Profile':
              next({
                path: `/people/${slug.Slug}`,
                replace: true,
              });
              break;
            case 'Office':
              API.Offices.Get(slug.Id).then((office) => {
                next({
                  path: `/offices/${office.OfficeSlug}`,
                  replace: true,
                });
              }).catch(() => {
                next();
              });
              break;
            default:
              next();
              break;
          }
        }).catch(() => {
          next();
        });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (from && from.path === to.path) return null;

    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
